import clientMaker from './clientMaker';

import store from "@/public/store";
import router from "@/public/router/router";

// import {LOGIN_URL} from "@/config";

let _startRequest;
let _ticker;

class __apiClient {
    constructor() {
        this.__buildClient();
    }

    __buildClient(sid = null) {
        if(this._apiClient) {
            this._apiClient.stop();
        }

        this._apiClient = clientMaker();

        this.__cloneClient();
    }

    __cloneClient() {
        Object.keys(this._apiClient).forEach(n => this[n] = this._apiClient[n]);
    }
}

let client = undefined;

export const apiClient = new Proxy({}, {
    get(target, prop) {
        if(prop === 'query') {
            return async (...args) => {
                const c = await client;
                const r = _startRequest ? _startRequest('query', args[0].query, args[0].variables) : null;

                return c.query(...args)
                    .then((res) => {
                        if(res.errors && res.errors.some((el) => el.message === "Unauthorized")){
                            store.commit('app/setAuthLogin', false);
                            // router.push({name: 'login'});
                        }

                        if(r) r.done(true, res);

                        return res;
                    })
                    .catch((e) => {
                        if(r) r.done(false, e);

                        return e;
                    });
            };
        }
        if(prop === 'mutate') {
            return async (...args) => {
                const c = await client;
                const r = _startRequest ? _startRequest('mutate', args[0].mutation, args[0].variables) : null;

                return c.mutate(...args)
                    .then((res) => {
                        if(res.errors && res.errors.some((el) => el.message === "Unauthorized")){
                            store.commit('app/setAuthLogin', false);
                            // router.push({name: 'login'});
                        }

                        if(r) r.done(true, res);

                        return res;
                    })
                    .catch((e) => {
                        if(r) r.done(false, e);

                        return e;
                    });
            };
        }
        // if(prop === 'subscribe') {
        //     return async (...args) => {
        //         const c = await client;
        //
        //         return c.subscribe(...args);
        //     };
        // }

        if(client[prop]) {
            return client[prop];
        }

        return undefined;
    },
});


export default apiClient;
export const _apiClient = apiClient;

export const rebuild_client = (sid = null) => {
    if(client) {
        return;

        client.stop();

        client.$$wsLink.subscriptionClient.reconnect = false;
        client.$$wsLink.subscriptionClient.close();
    }

    client = clientMaker()
};

// rebuild_client();

(async () => {
    let shiftClick = false;
    let oneClick = false;
    let fiveClick = false;

    let spyRequestsEnable = false;

    try {
        spyRequestsEnable = window.localStorage.getItem('spy_mode') === '1';
    } catch (e) {
        //
    }

    const _toggleSpyMode = () => {
        spyRequestsEnable = !spyRequestsEnable;

        try {
            window.localStorage.setItem("spy_mode", spyRequestsEnable ? '1' : '0');
        } catch (e) {
            //
        }
    };

    let requests = [];

    document.addEventListener('keypress', (e) => {
        if(e.key === '=') {
            shiftClick = true;
        } else if(e.key === '1') {
            oneClick = true;
        } else if(e.key === '5') {
            fiveClick = true;
        }

        toggleSpyMode();
    });

    document.addEventListener('keyup', (e) => {
        if(e.key === '=') {
            shiftClick = false;
        } else if(e.key === '1') {
            oneClick = false;
        } else if(e.key === '5') {
            fiveClick = false;
        }
    });

    let rootBlock;

    const toggleSpyMode = (activate) => {
        if(!activate) {
            if(!(shiftClick && oneClick && fiveClick)) {
                return;
            }

            _toggleSpyMode();
        }

        if(spyRequestsEnable) {
            _startRequest = (type, query, args) => {
                const request = {
                    type, query, args, tsStart: Date.now(), pending: true,
                };

                request.done = (success, answer) => {
                    request.pending = false;
                    request.success = success;
                    request.answer = answer;
                    request.tsEnd = Date.now();
                };

                requests.push(request);

                // console.log(request);

                return request;
            };

            {
                rootBlock = document.createElement('div');

                rootBlock.style.position = 'fixed';
                rootBlock.style.top = '0';
                rootBlock.style.right = '0';

                rootBlock.style.width = '300px';
                rootBlock.style.height = '500px';

                rootBlock.style.display = 'block';

                rootBlock.style.backgroundColor = '#000';
                rootBlock.style.zIndex = '9999';
            }

            const header = document.createElement('div');

            {
                rootBlock.append(header);

                header.style.position = 'relative';

                header.style.display = 'flex';
                header.style.justifyContent = 'space-between';
                header.style.alignItems = 'center';

                header.style.width = '100%';
                header.style.height = '50px';

                header.style.borderBottom = '1px solid grey';
            }

            const headerCounter = document.createElement('div');

            {
                header.append(headerCounter);

                headerCounter.style.padding = '10px';
                headerCounter.style.color = 'green';
                headerCounter.innerText = '0 requests';
            }

            const headerClear = document.createElement('div');

            {
                header.append(headerClear);

                headerClear.style.cursor = 'pointer';
                headerClear.style.padding = '10px';
                headerClear.style.color = 'red';
                headerClear.innerText = 'clear';

                headerClear.addEventListener('click', () => {
                    for(const r of requests) {
                        if(r._block) {
                            r._block.remove();
                        }
                    }

                    requests = [];
                });
            }

            const requestsBlock = document.createElement('div');

            {
                rootBlock.append(requestsBlock);

                requestsBlock.style.display = 'block';

                requestsBlock.style.width = '100%';
                requestsBlock.style.height = '449px';

                requestsBlock.style.overflow = 'auto';
            }

            _ticker = setInterval(() => {
                headerCounter.innerText = requests.length + ' requests';

                for(const request of requests) {
                    if(!request._block) {
                        request._block = document.createElement('div');
                        requestsBlock.append(request._block);

                        request._block.style.display = 'flex';
                        request._block.style.justifyContent = 'space-between';
                        request._block.style.alignItems = 'center';

                        request._block.style.cursor = 'pointer';

                        request._block.style.height = '40px';
                        request._block.style.padding = '5px';
                        request._block.style.borderBottom = '1px solid grey';

                        request._q = document.createElement('div');
                        request._block.append(request._q);

                        request._q.style.width = '220px';
                        request._q.style.textOverflow = 'ellipsis';
                        request._q.style.overflow = 'hidden';
                        request._q.style.whiteSpace = 'nowrap';
                        // request._q.style.width = '150px';

                        try {
                            for(const q of request.query.definitions) {
                                for(const a of q.selectionSet.selections) {
                                    request._q.innerText = '[' + (request.type === 'query' ? 'q' : 'm') + '] ' + a.name.value;
                                }
                            }
                        } catch (e) {
                            request._q.innerText = '[UNKNOWN] ' + request.type;
                        }

                        request._ts = document.createElement('div');
                        request._block.append(request._ts);

                        request._block.addEventListener('click', () => {
                            console.log('========================');
                            console.log(request._q.innerText);
                            console.log({
                                isPending: request.pending,
                                isSuccess: request.success,

                                type: request.type,
                                query: request.query.loc.source.body,
                                args: request.args,

                                response: request.answer,

                                startAt: request.tsStart,
                                endAt: request.tsEnd,

                                duration: request.tsEnd ? ((request.tsEnd - request.tsStart) / 1000).toFixed(2) + ' sec' : '',
                            });
                            console.log('========================');
                        });
                    }

                    if(request.pending) {
                        request._q.style.color = 'cyan';
                        request._ts.innerText = ((Date.now() - request.tsStart) / 1000).toFixed(2) + ' sec';
                    } else {
                        if(request.success) {
                            request._q.style.color = 'green';
                        } else {
                            request._q.style.color = 'red';
                        }

                        request._ts.innerText = ((request.tsEnd - request.tsStart) / 1000).toFixed(2) + ' sec';
                    }
                }
            }, 50);

            document.body.append(rootBlock);
        } else {
            rootBlock.remove();

            clearInterval(_ticker);

            requests = [];
        }
    };

    toggleSpyMode(true);
})();